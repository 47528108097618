<footer #componenteFooter
  class="flex flex-col lg:flex-row justify-between items-center
    max-h-20 min-h-15 m-1 mb-3
    {{ isHidden ? 'hidden' : '' }} 
    {{
    isTopvixBgColor
      ? 'bg-topvix-secondary text-gray-100 shadow-blue-50 border-blue-300'
      : idleBGColor
    }} 
    {{ !isTopvixBgColor ? 'border shadow' : '' }} 
    w-auto 
    {{
      fullWidth ? 'm-0' : 'rounded-lg'
    }}">

  <div class="w-full grid grid-cols-1 lg:grid-cols-3 px-1 md:px-4 py-3 items-center md:gap-2">
    <div class="w-full flex justify-center lg:justify-start items-center order-2 lg:order-1
          text-gray-500
        text-sm gap-2 md:p-0 
        {{
        isTopvixBgColor
          ? 'hover:text-topvix-primary'
          : 'hover:text-topvix-secondary'
      }} 
        hover:font-semibold"
    >
      © 2024 <a href="https://topvix.com.br/">Topvix™</a>. Todos os direitos
      reservados.
    </div>

    @if(withLogo) {
    <div class="w-full flex justify-center items-center order-1 lg:order-2">
      <!-- <img [src]="imagem" class="h-12 w-12 object-scale-down text-white" alt=""> -->

      <logo-topvix-svg
        [isTopvixLogoColor]="false"
        [logoHeight]="62"
        [logoWidth]="62"
      ></logo-topvix-svg>
    </div>
    } @else {
    <div
      class="w-full flex justify-center items-center gap-2 text-gray-50 order-2
      {{
        isTopvixBgColor
          ? 'hover:text-topvix-primary'
          : 'hover:text-topvix-secondary'
      }} 
      font-semibold
      text-xs"
    >
      Versão: <span>[{{ currentLocalVersion || "-" }}]</span>
      WSID: <span>[{{ currentWsId }}]</span>
    </div>
    }

    <div class="w-full flex items-center justify-center lg:justify-end order-3">
      <ul class="flex items-center justify-start gap-4 text-sm font-medium text-gray-500">
        <li (click)="mostraTexto('SOBRE')"
          class="hover:font-semibold cursor-pointer {{
            isTopvixBgColor
              ? 'hover:text-topvix-primary'
              : 'hover:text-topvix-secondary'
          }}">
          Sobre
        </li>

        <li (click)="mostraTexto('REGULAMENTO')"
          class="hover:font-semibold cursor-pointer {{
            isTopvixBgColor
              ? 'hover:text-topvix-primary'
              : 'hover:text-topvix-secondary'
          }}">
          Termos e condições
        </li>

        <li (click)="mostraTexto('POLITICA')"
          class="hover:font-semibold cursor-pointer {{
            isTopvixBgColor
              ? 'hover:text-topvix-primary'
              : 'hover:text-topvix-secondary'
          }}">
          Política de privacidade
        </li>

        <li
          class="hover:font-semibold cursor-pointer {{
            isTopvixBgColor
              ? 'hover:text-topvix-primary'
              : 'hover:text-topvix-secondary'
          }}">
          Contato
        </li>
      </ul>
    </div>
  </div>

</footer>


<texto-sistema-page *ngIf="showTexto && tipoTexto" [tipoTexto]="tipoTexto" (onClose)="showTexto=$event"></texto-sistema-page>
import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

import { etipo_texto_sistema, idleStatusType, IWsClientId } from '../../enums/Enums';
import { IdleService } from '../../services/idle.service';
import { CommonModule } from '@angular/common';
import { LogoTopvixSvgComponent } from "../logo-topvix-svg/logo-topvix-svg.component";
import { UtilitiesService } from '../../services/utilities.service';
import { TextoSistemaDisplayFormComponent } from "../../pages/texto-sistema-display-form/texto-sistema-display-form.component";
import { WebsocketService } from 'src/app/services/websocket.service';

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [CommonModule, LogoTopvixSvgComponent, TextoSistemaDisplayFormComponent],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.css',
})
export class FooterComponent implements OnInit, AfterViewInit {
  @Input() isHidden = false;

  @Input() set wsID(value: IWsClientId|undefined) {
    if(value) {
      this._currentWsId = value;
    }
  }

  @Input() set bgColor(color: 'default' | 'topvix') {
    if (color === 'topvix') this.isTopvixBgColor = true;
  }
  @Input() set showLogoImage(show: boolean) {
    this.withLogo = show;
  }
  @Input() fullWidth = false;

  @ViewChild('componenteFooter') componenteFooter!: ElementRef;

  withLogo = false;
  isTopvixBgColor = false;
  idleStatus?: idleStatusType;
  idleBGColor = 'bg-white';


  showTexto = false;
  tipoTexto?: etipo_texto_sistema;
  
  private _currentWsId?: IWsClientId;
  get currentWsId() {
    return this._currentWsId?.client_id|| 'N/D';
  }



  currentLocalVersion?: string;

  constructor(
    private readonly idleService: IdleService,
    private readonly utility: UtilitiesService, 
  ) {
    //monitorar as alterações de rota
    // initFlowbite();

    this.idleService.idleStatusObserver().subscribe((value) => {
      this.idleStatus = value;

      this.idleBGColor = this.idleService.getBGColor;
      // console.log('cor fundo atual:', this.idleBGColor);
    });
  }
  ngAfterViewInit(): void {
    if(this.componenteFooter) {
      // console.log('rodapé:', this.componenteFooter);
    }
  }

  ngOnInit(): void {
    // initFlowbite();

    this.currentLocalVersion = this.utility.getAppVersion();
  }

  mostraTexto(tipo: etipo_texto_sistema) {
    // console.log('mostraTexto:', tipo);
    this.tipoTexto = tipo;
    this.showTexto = true;
  }
}
